.About5Main {
  margin-top: 5rem;
}

.About5Parent {
}
.About5Parent h6 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.About5Child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
}
@media screen and (max-width: 576px) {
  .About5Child {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About5Child {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About5Child h2 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Playfair Display";
  font-size: 36px; 
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 57.6px */
  letter-spacing: -0.96px;
  width: 40%;
}
@media screen and (max-width: 576px) {
  .About5Child h2 {
    font-size: 24px;
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About5Child h2 {
    font-size: 24px;
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About5Child h2 {
    font-size: 24px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .About5Child h2 {
        font-size: 24px;
      }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.About5Child span {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 300;  
  line-height: 120%;
  letter-spacing: -0.96px;
}
@media screen and (max-width: 576px) {
  .About5Child span {
    font-size: 24px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About5Child span {
    font-size: 24px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About5Child span {
    font-size: 24px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .About5Child span {
        font-size: 24px;
      }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.About5Child p {
  color: var(--neutral-neutral-500, #2b2b2b);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
  width: 40%;
}
@media screen and (max-width: 576px) {
  .About5Child p {
    font-size: 16px;
    width: 100%;
    padding-top: 0.5rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About5Child p {
    font-size: 16px;
    width: 100%;
    padding-top: 0.5rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About5Child p {
    font-size: 16px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .About5Child p {
        font-size: 16px;
      }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About5Child1 {
  border: 1px solid var(--blue-blue-500, #eb950a);
  height: 20vh;
}
@media screen and (max-width: 576px) {
  .About5Child1 {
    height: 0vh;
    width: 100%;
    margin-top: 0.5rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About5Child1 {
    height: 0vh;
    width: 100%;
    margin-top: 0.5rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.About5Img {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  border-radius: 12px;
}
@media screen and (max-width: 576px) {
  .About5Img {
    padding-top: 3rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About5Img {
    padding-top: 3rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.About5Img img {
  border-radius: 12px;
}

@media screen and (max-width: 576px) {
  .About5Img img {
    height: 30vh;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About5Img img {
    height: 40vh;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
    .About5Img img {
        height: 40vh;
      }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .About5Img img {
        height: 40vh;
      }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
