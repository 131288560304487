.Contact1Main {
  margin-top: 8rem;
}

.Contact1Parent {
  width: 60%;
}

.Contact1Parent h1 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Playfair Display";
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 67.2px */
}
@media screen and (max-width: 576px) {
.Contact1Parent h1 {
  font-size: 24px;
}
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Contact1Parent h1 {
    font-size: 24px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Contact1Parent h1 {
    font-size: 24px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Contact1Parent h1 {
    font-size: 28px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .Contact1Parent h1 {
    font-size: 32px;
  }
}

.Contact1Parent h5 {
  color: var(--neutral-neutral-500, #2b2b2b);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
  padding-top: 0.5rem;
}
@media screen and (max-width: 576px) {
  .Contact1Parent h5 {
    font-size: 16px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Contact1Parent h5 {
    font-size: 16px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Contact1Parent h5 {
    font-size: 16px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Contact1Parent h5 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}


.Contact1Child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;
  width: 60%;
}
@media screen and (max-width: 576px) {
  .Contact1Child {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Contact1Child {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Contact1Child {
    width: 80%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}


.Contact1Child1 h4 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 41.6px */
  letter-spacing: -0.64px;
  padding-top: 0.5rem;
}
@media screen and (max-width: 576px) {
  .Contact1Child1 h4 {
    font-size: 24px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Contact1Child1 h4 {
    font-size: 24px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Contact1Child1 h4 {
    font-size: 24px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Contact1Child1 h4 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Contact1Child1 h5 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
  padding-top: 0.5rem;
}
@media screen and (max-width: 576px) {
  .Contact1Child1 h5 {
    font-size: 16px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Contact1Child1 h5 {
    font-size: 16px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Contact1Child1 h5 {
    font-size: 16px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
