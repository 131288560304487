.About7Primary {
  background: #fff;
  padding: 5rem 0rem;
}

.About7Main {
  /* margin-top: 5rem; */
}

.About7MainParent {
  width: 40%;
}
@media screen and (max-width: 576px) {
  .About7MainParent {
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About7MainParent {
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About7MainParent {
    width: 60%;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .About7MainParent {
    width: 60%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About7Main h4 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 41.6px */
  letter-spacing: -0.64px;
}
@media screen and (max-width: 576px) {
  .About7Main h4 {
    font-size: 24px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About7Main h4 {
    font-size: 24px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About7Main h4 {
    font-size: 24px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .About7Main h4 {
    font-size: 28px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About7Parent {
    border: 1px solid var(--blue-blue-500, #eb950a);
    margin-top: 1rem;
  }
  @media screen and (max-width: 576px) {
    .About7Parent {
      margin-top: 0.5rem;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .About7Parent {
      margin-top: 0.5rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .About7Parent {
      margin-top: 0.5rem;
      
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media screen and (min-width: 1201px) and (max-width: 1400px) {
  }

.About7Main h5 {
  color: var(--neutral-neutral-500, #2b2b2b);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
  padding-top: 1.5rem;
  width: 70%;
}
@media screen and (max-width: 576px) {
  .About7Main h5 {
    font-size: 16px;
    width: 100%;
    padding-top: 0.5rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About7Main h5 {
    font-size: 16px;
    width: 100%;
    padding-top: 0.5rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About7Main h5 {
    font-size: 16px;
    width: 100%;
    padding-top: 0.5rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.About7Main button {
  border-radius: 2px;
  border: 1px solid var(--blue-blue-500, #1c233d);
  background: var(
    --blue-blue-500,
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    #1c233d
  );

  color: #fff;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  padding: 0.5rem 1rem;
  margin-top: 2rem;
}

@media screen and (max-width: 576px) {
  .About7Main button {
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About7Main button {
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About7Main button {
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
