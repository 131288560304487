.About4Main {
  background: var(--blue-blue-50, #f1f3f9);
  padding: 3rem 0;
  padding-bottom: 6rem;

  background: var(
    --blue-blue-500,
    linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    #1c233d
  );  
}

.About4Parent {
  display: flex;
  flex-direction: column;
  text-align: center;
}  
.About4Child {
  width: 50%;
  margin-left: 25%;
  margin-top: 3rem;
}

.About4Child h2 {
  color: var(--blue-blue-500, #fff);
  text-align: center;
  font-family: "Playfair Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 41.6px */
  letter-spacing: -0.64px;
}
@media screen and (max-width: 576px) {
  .About4Child h2 {
    font-size: 24px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About4Child h2 {
    font-size: 24px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About4Child h2 {
    font-size: 26px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .About4Child h2 {
    font-size: 28px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About4Child p {
  color: var(--neutral-neutral-500, #fff);
  text-align: center;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
  padding-top: 1rem;
}

@media screen and (max-width: 576px) {
  .About4Child p {
    font-size: 16px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About4Child p {
    font-size: 16px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About4Child p {
    font-size: 18px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .About4Child p {
    font-size: 18px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About4Child1 {
  padding-top: 0.5rem;
  border-bottom: 1px solid var(--blue-blue-500, #eb950a);
}
 