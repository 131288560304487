.Page5Main {
  background: var(--blue-blue-50, #f1f3f9);
  margin-top: 5rem;

  background: var(
    --blue-blue-500,
    linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    #1c233d
  );
}

.Page5Parent {
  padding-top: 3rem;
}

.Page5People h6 {
  color: var(--blue-blue-500, #fff);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.Page5Child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .Page5Child {
    flex-direction: column;
    align-items: left;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page5Child {
    /* flex-direction: column;
    align-items: left; */
    justify-content:space-between;
    align-items: center;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Page5Child {
    display: flex;
    justify-content:space-between;
    align-items: center;
  }  
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Page5Child {
    display: flex;
    justify-content:space-between;
    align-items: center;
  }  
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Page5Child1 {
  width: 40%;
}

@media screen and (max-width: 576px) {
  .Page5Child1 {
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page5Child1 {
    width: 100% !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Page5Child1 {
    width: 40%;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Page5Child1 h2 {
  color: var(--blue-blue-500, #fff);
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 57.6px */
  letter-spacing: -0.96px;
}
@media screen and (max-width: 576px) {
  .Page5Child1 h2 {
    font-size: 24px;
    width: 60%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page5Child1 h2 {
    font-size: 24px;
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Page5Child1 h2 {
    font-size: 28px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Page5Child1 h2 {
    font-size: 32px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.Page5Child1 p {
  color: var(--neutral-neutral-500, #fff);
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; 
  letter-spacing: -0.48px;
  padding-top: 1rem;
}
@media screen and (max-width: 576px) {
  .Page5Child1 p {
    font-size: 16px;
    width: 80%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page5Child1 p {
    font-size: 16px;
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.Page5Child1 button {
  border-radius: 2px;
border: 0.6px solid var(--neutral-white, #FFF);
  /* background: #f1f3f9; */
  background: var(--blue-blue-500, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #1C233D);
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  color: var(--blue-blue-500, #fff);

  /* Text/Regular/Normal */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.Page5Child2 {
  width: 40%;
}

@media screen and (max-width: 576px) {
  .Page5Child2 {
    width: 100%;
   margin-top: 3rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page5Child2 {
    width: 100%;
   margin-top: 3rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Page5Child2 {
    width: 40%;
   margin-top: 3rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Page5Child2 {
    width: 40%;
   margin-top: 3rem;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

@media screen and (max-width: 576px) {
  .Page5Child2 img{
    height: 300px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page5Child2 img{
    height: 300px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Page5Child2 img{
    height: 400px !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Page5Child2 img{
    height: 400px !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .Page5Child2 img{
    height: 400px !important;
  }
}
