 eslint-disable-next-line

.Page4Main {
  margin-top: 5rem;
}

.Page4Header h6 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.Page4Parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}
@media screen and (max-width: 576px) {
  .Page4Parent {
    display: flex;
    flex-direction: column !important;
    align-items: start;
    gap: 10px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page4Parent {
    display: flex;
    flex-direction: column !important;
    align-items: start;
    gap: 10px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Page4Child1 {
    width: 40%;
}
@media screen and (max-width: 576px) {
  .Page4Child1 {
    width: 100%;
}
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page4Child1 {
    width: 100%;
}
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Page4Child1 h2 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 57.6px */
  letter-spacing: -0.96px;
}

@media screen and (max-width: 576px) {
  .Page4Child1 h2 {
    font-size: 24px;
    width: 60%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page4Child1 h2 {
    font-size: 24px;
    width: 60%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Page4Child1 h2 {
    font-size: 26px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Page4Child1 h2 {
    font-size: 26px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}


.Page4SubChild {
    border: 1px solid #EB950A;
    height: 20vh;
}
@media screen and (max-width: 576px) {
  .Page4SubChild {
    border: 1px solid #EB950A;
    height: 0vh;
    width: 80%;
    text-align: left;
}
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page4SubChild {
    border: 1px solid #EB950A;
    height: 0vh;
    width: 80%;
    text-align: left;
}
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Page4SubChild {
    border: 1px solid #EB950A;
    height: 10vh;
}
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Page4SubChild {
    border: 1px solid #EB950A;
    height: 10vh;
}
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Page4Child2 {
    width: 40%;
}

@media screen and (max-width: 576px) {
  .Page4Child2 {
    width: 100%;
}
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page4Child2 {
    width: 100%;
}
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Page4Child2 h5 {
    color: var(--neutral-neutral-500, #2B2B2B);
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.48px;
}
@media screen and (max-width: 576px) {
  .Page4Child2 h5 {
    font-size: 14px;
    width: 80%;
}
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page4Child2 h5 {
    font-size: 15px;
    width: 80%;
}
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Page4Child2 h5 {
    font-size: 16px;
}
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Page4Child2 h5 {
    font-size: 16px;
}
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}


/* Mapped Objects */

  .whatwedoMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 8rem;
  }
  @media screen and (max-width: 576px) {
    .whatwedoMain {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column !important;
      justify-content: space-between;
      margin-top: 3rem;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .whatwedoMain {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column !important;
      justify-content: space-between;
      margin-top: 3rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .whatwedoMain {
      margin-top: 3rem;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .whatwedoMain {
      margin-top: 3rem;
    }
  }
  
  
  .whatwedoparent {
    flex-basis: calc(50% - 40px);
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .whatweflex h6 {
    text-align: left;
    color: var(--blue-blue-500, #1C233D);
    font-family: "Open Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 41.6px */
    letter-spacing: -0.64px;
  
  }  
  
  @media screen and (max-width: 576px) {
    .whatweflex h6 {
      font-size: 16px;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .whatweflex h6 {
      font-size: 18px;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .whatweflex h6 {
      font-size: 18px;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
     .whatweflex h6 {
      font-size: 20px;
    }
  }
  
  .whatwedodiv {
    border-bottom: 0.4px solid var(--blue-blue-500, #1C233D);
    padding-top: 0.5rem;
  }

  @media screen and (max-width: 576px) {
    .whatwedodiv {
      border-bottom: 0.4px solid var(--blue-blue-500, #1C233D);
      padding-top: 0.2rem;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .whatwedodiv {
      border-bottom: 0.4px solid var(--blue-blue-500, #1C233D);
      padding-top: 0.2rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  