.About6Primary {
  background: var(--blue-blue-500, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #1C233D);
}

.About6Main {
  margin-top: 5rem;
}
@media screen and (max-width: 576px) {
  .About6Main {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About6Main {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About6Parent {
  padding-top: 3rem;
}
@media screen and (max-width: 576px) {
  .About6Parent {
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About6Parent h6 {
  color: var(--blue-blue-500, #1c233d);
  color: #fff;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.About6Child {
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 576px) {
  .About6Child {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About6Child {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About6Child h2 {
  color: var(--blue-blue-500, #1c233d);
  color: #fff;
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 57.6px */
  letter-spacing: -0.96px;
  width: 40%;
}
@media screen and (max-width: 576px) {
  .About6Child h2 {
    font-size: 24px;
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About6Child h2 {
    font-size: 24px;
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
    .About6Child h2 {
        font-size: 28px;
      }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .About6Child h2 {
        font-size: 32px;
      }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About6Child1 {
  border: 1px solid var(--blue-blue-500, #eb950a);
  height: 20vh;
}
@media screen and (max-width: 576px) {
  .About6Child1 {
    height: 0vh;
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About6Child1 {
    height: 0vh;
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About6Child p {
  color: var(--neutral-neutral-500, #2b2b2b);
  color: #fff;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
  width: 40%;
}
@media screen and (max-width: 576px) {
  .About6Child p {
    font-size: 16px;
    width: 100%;
    padding-top: 0.5rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About6Child p {
    font-size: 16px;
    width: 100%;
    padding-top: 0.5rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
    .About6Child p {
        font-size: 16px;
      }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .About6Child p {
        font-size: 18px;
      }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
