.Contact2Main {
  margin-top: 5rem;
}
.Contact2Main h4 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 41.6px */
  letter-spacing: -0.64px;
}

.Contact2Parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}
@media screen and (max-width: 576px) {
  .Contact2Parent {
    align-items: flex-start;
    margin-top: 2rem;
    flex-direction: column;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Contact2Parent {
    align-items: flex-start;
    margin-top: 2rem;
    flex-direction: column;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Contact2Child h5 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}
@media screen and (max-width: 576px) {
  .Contact2Child h5 {
    font-size: 20px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Contact2Child h5 {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Contact2Child h5 {
    font-size: 20px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Contact2Child p {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
  text-decoration-line: underline;
}
@media screen and (max-width: 576px) {
  .Contact2Child p {
    font-size: 16px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Contact2Child p {
    font-size: 16px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Contact2Child p {
    font-size: 18px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Contact2Child p {
    font-size: 20px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Contact2Child a {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
  text-decoration-line: underline;
}

.Contact2Child2 img {
  border-radius: 12px;
}
@media screen and (max-width: 576px) {
.Contact2Child2 img {
  border-radius: 12px;
  width: 100%;
}
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Contact2Child2 img {
    border-radius: 12px;
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Contact2Child2 img {
    border-radius: 12px !important;
    height: 40vh;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Contact2Child2 img {
    border-radius: 12px !important;
    height: 40vh;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
