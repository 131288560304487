.Page2Main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0rem;
    text-align: center;
    justify-content: space-between;
    background: var(--blue-blue-50, #F1F3F9);
    padding: 5rem 0rem;
}

/* .Page2Main {
    position: relative;
    width: 60%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column; 
    align-items: center;
    margin-left: 20%;
    margin-top: 5rem;
  }

  .Page2Main::before {
    content: "";
    background: linear-gradient(180deg, rgba(10, 11, 13, 0) 10.93%, #0a0b0d 100%),
      url(../../../public/images/Page2Img.svg);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  } */





@media screen and (max-width: 576px) {
}
@media screen and (min-width: 577px) and (max-width: 768px) {
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}


.Page2Parent{
  padding-top: 3rem;
}

.Page2Parent h6{
    color: var(--neutral-neutral-500, #2B2B2B);
    text-align: center;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.48px;
}
@media screen and (max-width: 576px) {
    .Page2Parent h6{
        font-size: 14px;
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .Page2Parent h6{
        font-size: 14px;
    }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
    .Page2Parent h6{
        font-size: 16px;
    }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Page2Parent h3 {
    color: var(--blue-blue-500, #1C233D);
    font-family: "Bodoni Moda";
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 76.8px */
    text-transform: uppercase;
}

@media screen and (max-width: 576px) {
    .Page2Parent h3 {
        font-size: 24px;
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .Page2Parent h3 {
        font-size: 24px;
    }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
    .Page2Parent h3 {
        font-size: 26px;
    }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .Page2Parent h3 {
        font-size: 26px;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}