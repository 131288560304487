.fromParent {
    margin-top: 5rem;
    width: 60%;
  }
  
  .formMainParent {
      margin-top: 2rem;
  }
  
  .formMain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .formMain h6 {
    color: var(--blue-blue-500, #1C233D);
    text-align: center;
    
    /* Heading/H2/Light */
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 57.6px */
    letter-spacing: -0.96px;
  }
  
  @media screen and (max-width: 576px) {
    .formMain h6 {
      font-size: 20px;
      line-height: 26px;
      text-align: center;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .formMain h6 {
      font-size: 22px;
      line-height: 26px;
      text-align: center;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .formMain h6 {
      font-size: 24px;
      line-height: 26px;
      text-align: center;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .formMain h6 {
      font-size: 26px;
      line-height: 26px;
      text-align: center;
    }
  }
  @media screen and (min-width: 1201px) and (max-width: 1400px) {
    .formMain h6 {
      font-size: 26px;
      line-height: 26px;
      text-align: center;
    }
  }
  
  
  .formMain p {
    color: var(--foundation-black-black-50, #e7e7e7);
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
  
  .formControl {
    background: #000;
    color: var(--foundation-black-black-100, #fff) !important;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
  .formControl:focus {
    background: #000 !important;
  }
  
  .formbutton {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .formbutton button {
    padding: 0.5rem 1.5rem;
  
    color: var(--foundation-yellow-yellow-50, #fffff8);
    text-align: center;

    border-radius: 2px;
    border: 1px solid var(--blue-blue-500, #1C233D);
    background: var(--blue-blue-500, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #1C233D);

  
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }