.About2Main {
    margin-top: 5rem;
    background: var(--neutral-light-gray, #F4F4F4);
    padding: 3rem 2rem;
}

.About2Parent {
    padding-top: 3rem;
}
.About2Parent h6{
    color: var(--blue-blue-500, #1C233D);
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.48px;
}

.About2Child {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 576px) {
    .About2Child {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .About2Child {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About2Child h2{
    color: var(--blue-blue-500, #1C233D);
    font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 57.6px */
    letter-spacing: -0.96px;
    width: 40%;
}
@media screen and (max-width: 576px) {
    .About2Child h2{
        font-size: 20px;
        letter-spacing: -0.96px;
        width: 100%;
        padding-top: 1rem;
        font-weight: 400;
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .About2Child h2{
        font-size: 20px;
        letter-spacing: -0.96px;
        width: 100%;
        padding-top: 1rem;
        font-weight: 400;
    }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
    .About2Child h2{
        font-size: 20px;
        letter-spacing: -0.96px;
        width: 80%;

        font-weight: 400;
    }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .About2Child h2{
        font-size: 20px;
        letter-spacing: -0.96px;
        width: 80%;
        font-weight: 400;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.About2Child p{
    color: var(--neutral-neutral-500, #2B2B2B);
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.48px;
    width: 40%;
}
@media screen and (max-width: 576px) {
    .About2Child p{
        font-size: 16px;
        width: 100%;
        line-height: 170%;
        padding-top: 1rem;
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .About2Child p{
        font-size: 16px;
        width: 100%;
        line-height: 170%;
        padding-top: 1rem;
    }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
    .About2Child p{
        font-size: 16px;
        width: 100%;
        line-height: 170%;
        padding-top: 1rem;
    }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .About2Child p{
        font-size: 16px;
        width: 100%;
        line-height: 170%;
        padding-top: 1rem;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.AboutBro {
    text-align: center;
    width: 70%;
    margin-left: 10%;
    margin-top: 3rem;
}
.AboutBro p{
    color: var(--neutral-neutral-500, #2B2B2B);
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.48px;
}
@media screen and (max-width: 576px) {
    .AboutBro p{
        font-size: 16px;
        width: 100%;
        line-height: 170%;
        padding-top: 1rem;
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .AboutBro p{
        font-size: 16px;
        width: 100%;
        line-height: 170%;
        padding-top: 1rem;
    }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
    .AboutBro p{
        font-size: 16px;
        width: 100%;
        line-height: 170%;
        padding-top: 1rem;
    }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .AboutBro p{
        font-size: 16px;
        width: 100%;
        line-height: 170%;
        padding-top: 1rem;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}