.ParentNav {
  /* background: var(--neutral-white, #fff); */
  background: var(--blue-blue-500, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #1C233D);
}

@media screen and (max-width: 576px) {
  .ParentNav {
    /* background: var(--neutral-white, #fff); */
  }
}
@media screen and (max-width: 576px) {
  .navbarLogoMobil img {
    height: 50px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .navbarLogoMobil img {
    height: 50px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .navbarLogoMobil img {
    height: 70px !important;
  }
}

@media screen and (max-width: 576px) {
  .navbarGrandMain ul {
    margin-left: 0rem !important;
  }
}
.navbarGrandMain {
  display: flex;
  justify-content: end;
}

@media screen and (max-width: 576px) {
  .navbarGrandMain {
    display: flex;
    justify-content: left !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .navbarGrandMain {
    display: flex;
    justify-content: left !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .navbarGrandMain {
    display: flex;
    justify-content: left !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 991px) {
  .navbarGrandMain {
    display: flex;
    justify-content: left !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .NavToggleImg {
   background: white;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .NavToggleImg {
    background: white;
   }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .NavToggleImg {
    background: white;
   }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .NavToggleImg {
    background: white;
   }
}


.navbarMain {
  /* display: flex; */
  gap: 40px;
  margin-right: 2.5rem;
}
@media screen and (max-width: 576px) {
  .navbarMain {
    justify-content: left !important;
    gap: 20px;
    margin-right: 0rem !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .navbarMain {
    justify-content: left !important;
    gap: 20px;
    margin-right: 0rem !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .navbarMain {
    justify-content: left !important;
    gap: 20px;
    margin-right: 0rem !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 991px) {
  .navbarMain {
    justify-content: left !important;
    gap: 20px;
    margin-right: 0rem !important;
  }
}

.navbarLi a {
  text-decoration: none;
  color: var(--neutral-neutral-400, #555);
  color: #fff ;
  /* Text/Regular/Normal */
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

.navbarLi a:hover {
  /* color: var(--foundation-yellow-yellow-500, #1c233d); */
  border-bottom: 1px solid var(--blue-blue-500, #fff);
}
.navbarLi a:active {
  /* color: var(--foundation-yellow-yellow-500, #1c233d); */
}
.navbarLi a:focus {
  /* color: var(--foundation-yellow-yellow-500, #1c233d); */
  border-bottom: 1px solid var(--blue-blue-500, #fff);
}

@media screen and (max-width: 576px) {
  .navbarLi a {
    font-size: 14px;
    font-weight: 600;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .navbarLi a {
    font-weight: 600;
    font-size: 14px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .navbarLi a {
    font-weight: 600;
    font-size: 14px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .navbarLi a {
    font-weight: 600;
    font-size: 14px;
  }
}

.NavButton a {
  color: var(--neutral-neutral-400, #555);
  color: #fff;
  text-align: left;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
}
.NavButton a:focus {
  /* color: var(--foundation-yellow-yellow-500, #1c233d); */
  border-bottom: 1px solid var(--blue-blue-500, #fff);
}
.NavButton a:active {
  /* color: var(--foundation-yellow-yellow-500, #1c233d); */
  border-bottom: 1px solid var(--blue-blue-500, #fff);
}
.NavButton a:hover {
  /* color: var(--foundation-yellow-yellow-500, #1c233d); */
  border-bottom: 1px solid var(--blue-blue-500, #fff);
}

@media screen and (max-width: 576px) {
  .NavButton {
    margin-top: 1.5rem;
    width: 100% !important;
  }
}
@media screen and (max-width: 576px) {
  .NavButton a {
    width: 100% !important;
    padding: 0rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .NavButton {
    margin-top: 1.5rem;
    width: 100% !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .NavButton a {
    width: 100% !important;
    padding: 0rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .NavButton {
    margin-top: 1.5rem;
    width: 100% !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .NavButton a {
    width: 30% !important;
    padding: 0rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 991px) {
  .NavButton {
    margin-top: 1.5rem;
    width: 100% !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 991px) {
  .NavButton a {
    width: 30% !important;
    padding: 0rem;
  }
}
