/* Page3.module.css */
.page3Container {
  background-image: url("/public/AboutImage.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  padding-top: 10rem;
  margin-top: 0rem;
}

@media screen and (max-width: 576px) {
  .page3Container {
    background-image: url("/public/AboutImage.svg");
    height: 70vh;
    padding-top: 3rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .page3Container {
    background-image: url("/public/AboutImage.svg");
    height: 70vh;
    padding-top: 5rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .page3Container {
    height: 70vh;
    padding-top: 5rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .page3Container {
    height: 70vh;
    padding-bottom: 25rem !important;
    padding-top: 5rem;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .page3Container {
    height: 70vh;
    padding-bottom: 30rem !important;
  }
}

.page3Parent h6 {
  color: var(--neutral-white, #fff);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.page3Child {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 576px) {
  .page3Child {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .page3Child {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.page3Child1 {
  width: 40%;
}
@media screen and (max-width: 576px) {
  .page3Child1 {
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .page3Child1 {
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.page3Child1 h3 {
  color: var(--neutral-white, #fff);
  font-family: "Open Sans";
  font-size: 80px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 96px */
}
@media screen and (max-width: 576px) {
  .page3Child1 h3 {
    font-size: 40px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .page3Child1 h3 {
    font-size: 40px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .page3Child1 h3 {
    font-size: 60px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .page3Child1 h3 {
    font-size: 60px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.page3Child2 {
  width: 40%;
}
@media screen and (max-width: 576px) {
  .page3Child2 {
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .page3Child2 {
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.page3Child2 h5 {
  color: var(--neutral-white, #fff);

  /* Heading/H5/Light */
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}
@media screen and (max-width: 576px) {
  .page3Child2 h5 {
    font-size: 18px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .page3Child2 h5 {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .page3Child2 h5 {
    font-size: 18px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .page3Child2 h5 {
    font-size: 20px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
