.About1Main {
  margin-top: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .About1Main {
    margin-top: 5rem;
    align-items: center;
    flex-direction: column;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About1Main {
    margin-top: 5rem;
    /* align-items: center; */
    /* flex-direction: column; */
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.AboutParent  {
    width: 60%;
}
@media screen and (max-width: 576px) {
  .AboutParent  {
    width: 100%;
    text-align: center;
}
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .AboutParent  {
    width: 45%;
    /* text-align: center; */
}
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.AboutParent h6 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

@media screen and (max-width: 576px) {
  .AboutParent h6 {
    font-size: 18px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .AboutParent h6 {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .AboutParent h6 {
    font-size: 18px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}


.AboutParent p {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Playfair Display";
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 67.2px */
  width: 80%;
}
@media screen and (max-width: 576px) {
  .AboutParent p {
    font-size: 16px;
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .AboutParent p {
    font-size: 24px;
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .AboutParent p {
    font-size: 24px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .AboutParent p {
    font-size: 24px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .AboutParent p {
    font-size: 24px;
    width: 70%;
  }
}
.AboutChild {
}
@media screen and (max-width: 576px) {
  .AboutChild {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .AboutChild {
    /* margin-top: 2rem; */
    width: 45% !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
 
.AboutChild img {
  border-radius: 12px !important;
  /* height: 100vh !important; */
}
@media screen and (max-width: 576px) {
  .AboutChild img {
    height: 40vh !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .AboutChild img {
    height: 40vh !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .AboutChild img {
    height: 40vh !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .AboutChild img {
    height: 60vh !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .AboutChild img {
    height: 60vh !important;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
  .AboutChild img {
    height: 60vh !important;
  } 
}