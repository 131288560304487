.page1Primary {
  padding-top: 10rem;
  padding-bottom: 10rem;
  background: var(
    --blue-blue-500,
    linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    #1c233d
  );  
}
@media screen and (max-width: 576px) {
  .page1Primary {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Page1Parent {
  /* margin-top: 8rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 6rem; */
}

@media screen and (max-width: 576px) {
  .Page1Parent {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center !important;
    padding-bottom: 0rem !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page1Parent {
    margin-top: 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    padding-bottom: 0rem !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Page1Parent {
    padding-bottom: 0rem !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Page1Parent {
    justify-content: space-around;

    padding-bottom: 0rem !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Page1Child {
  width: 55%;
}
@media screen and (max-width: 576px) {
  .Page1Child {
    text-align: center !important;
    width: 80%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page1Child {
    /* text-align: left !important; */
    /* width: 60%; */
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Page1Child {
    width: 45%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Page1Child h2 {
  /* color: var(--blue-blue-500, #1c233d); */
  color: #fff;
  font-family: "Playfair Display";
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 67.2px */
}

@media screen and (max-width: 576px) {
  .Page1Child h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page1Child h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Page1Child h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Page1Child h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.Page1Child p {
  /* color: var(--neutral-neutral-900, #121212); */
  color: #fff;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: -0.48px;
  padding-top: 1rem;
}
@media screen and (max-width: 576px) {
  .Page1Child p {
    font-size: 18px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Page1Child p {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Page1Child p {
    font-size: 18px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Page1Child p {
    font-size: 18px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.page1Sister img {
  border-radius: 12px !important;
}
@media screen and (max-width: 576px) {
  .page1Sister {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .page1Sister img {
    border-radius: 12px !important;
    height: 50vh !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .page1Sister {
    margin-top: 1rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .page1Sister img {
    border-radius: 12px !important;
    height: 40vh !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .page1Sister img {
    border-radius: 12px !important;
    height: 50vh !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .page1Sister img {
    border-radius: 12px !important;
    height: 50vh !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .page1Sister img {
    border-radius: 12px !important;
    height: 60vh !important;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
  .page1Sister img {
    border-radius: 12px !important;
    height: 60vh !important;
  }
}
