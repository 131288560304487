
.footerMainParent {
    padding-bottom: 2rem !important;
    margin-top: 5rem;
  }
  
  @media screen and (max-width: 576px) {
    .footerMainParent {
      /* background: #0c0c0c; */
      text-align: center !important;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerMainParent {
      /* background: #0c0c0c; */
      text-align: center !important;
    }
  }
  
  .footerMain {
    display: flex;
    min-height: 80vh;
    justify-content: space-between !important;
  }
  
  @media screen and (max-width: 576px) {
    .footerMain {
      flex-direction: column;
      min-height: 80vh;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerMain {
      flex-direction: column;
      min-height: 80vh;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .footerMain {
      display: flex;
      min-height: 80vh;
    }
  }
  
  .footerParent {
    /* width: 25%; */
    /* margin-left: 4rem; */
    margin-top: 6rem;
  }
  
  @media screen and (max-width: 576px) {
    .footerParent {
      width: 100%;
      margin-left: 0rem;
      margin-top: 2rem;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerParent {
      width: 100%;
      margin-left: 0rem;
      margin-top: 2rem;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .footerParent {
      width: 65%;
      margin-left: 0rem;
      margin-top: 6rem;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .footerParent {
      width: 65%;
      margin-left: 0rem;
      margin-top: 6rem;
    }
  }
  
  @media screen and (max-width: 576px) {
    .footerParent img {
      height: 30px !important;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerParent img {
      height: 30px !important;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .footerParent img {
      height: 35px !important;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .footerParent img {
      height: 35px !important;
    }
  }
  
  
  .footerParent h6 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: var(--blue-blue-500, #1C233D);
    padding-top: 2rem;
  }
  .footerParent p{
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--blue-blue-500, #1C233D);
  }
  
  @media screen and (max-width: 576px) {
    .footerParent h6 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      /* or 18px */
  
      text-align: center;
      color: var(--blue-blue-500, #1C233D);
      padding-top: 2rem;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerParent h6 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      text-align: center;
      color: var(--blue-blue-500, #1C233D);
      padding-top: 2rem;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .footerParent h6 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      text-align: left;
      color: var(--blue-blue-500, #1C233D);
      padding-top: 2rem;
    }
  }
  
  
  .footerIcons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
  }
  
  @media screen and (max-width: 576px) {
    .footerIcons {
      display: none;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerIcons {
      display: none;
    }
  }
  
  
  .footericons2 {
    display: flex;
    flex-wrap: wrap;
  }
  
  .footericons2 img {
    padding-left: 0rem !important;
    padding-right: 0.5rem !important;
    cursor: pointer;
  }
  
  .footericons2 h6 {
    padding-right: 1rem;
    padding-top: 2rem !important;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: var(--blue-blue-500, #1C233D);
    cursor: pointer;
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .footericons2 h6 {
      padding-right: 1rem;
      padding-top: 0.6rem !important;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: var(--blue-blue-500, #1C233D);
      cursor: pointer;
    }
  }
  
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .footericons2 h6 {
      padding-right: 1rem;
      padding-top: 0.6rem !important;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: var(--blue-blue-500, #1C233D);
      cursor: pointer;
    }
  }
  
  
  
  .footerChildren {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    margin-top: 6rem;
    padding-left: 10rem !important;
  }
  
  @media screen and (max-width: 576px) {
    .footerChildren {
      display: grid;
      grid-template-columns: 50% 50%;
      width: 100% !important;
      margin-top: 2rem;
      padding-left: 0rem !important;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerChildren {
      display: grid;
      grid-template-columns: 50% 50%;
      width: 100% !important;
      margin-top: 3rem;
      padding-left: 0rem !important;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .footerChildren {
      /* display: grid;
      grid-template-columns: 38% 38%; */
      width: 100% !important;
      margin-top: 6rem;
      padding-left: 0rem !important;
      display: flex !important;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .footerChildren {
      /* display: grid;
      grid-template-columns: 38% 38%; */
      width: 100% !important;
      margin-top: 6rem;
      padding-left: 0rem !important;
      display: flex !important;
    }
  }
  
  
  
  
  .footerChild {
    padding-left: 6rem;
    padding-bottom: 3rem;
  }
  
  @media screen and (max-width: 576px) {
    .footerChild {
      padding-left: 0rem !important;
      padding-bottom: 1rem;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerChild {
      padding-left: 0rem !important;
      padding-bottom: 0rem;
      padding-bottom: 1rem;
    }
  }
  
  .footerChild h3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    /* text-align: center; */
    text-align: left;
    color: var(--blue-blue-500, #1C233D);
  }
  
  @media screen and (max-width: 576px) {
    .footerChild h3 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 140%;
      /* text-align: center; */
      text-align: left;
      color: var(--blue-blue-500, #1C233D);
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerChild h3 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      /* text-align: center; */
      text-align: left;
      color: var(--blue-blue-500, #1C233D);
    }
  }
  
  .footerChild p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* or 22px */
    text-align: left;
    cursor: pointer !important;
    color: var(--blue-blue-500, #1C233D) !important;
  }
  
  @media screen and (max-width: 576px) {
    .footerChild p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      /* text-align: center; */
      text-align: left;
      color: var(--blue-blue-500, #1C233D);
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerChild p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      /* text-align: center; */
      text-align: left;
      color: #fafafa;
    }
  }
  
  .footerChild a {
    text-decoration: none;
  }
  
  
  
  .footerIconsMobile {
    display: none !important;
  }
  
  @media screen and (max-width: 576px) {
    .footerIconsMobile {
      display: unset !important;
      display: flex !important;
      justify-content: space-evenly;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerIconsMobile {
      display: unset !important;
      display: flex !important;
      justify-content: space-evenly;
    }
  }
  
  @media screen and (max-width: 576px) {
    .footericons2 .img {
      height: 30px !important;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footericons2 .img {
      height: 30px !important;
    }
  }
  
  
  
  .footerCopyright {
    border-top: 1px solid #EB950A;
    width: 100% !important;

  }
  
  @media screen and (max-width: 576px) {
    .footerCopyright {
      border-top: 1px solid #EB950A;
      width: 100% !important;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerCopyright {
      border-top: 1px solid #EB950A;
      width: 100% !important;
    }
  }
  
  .footerCopyright p {
    padding-top: 2rem;
    color: var(--blue-blue-500, #1C233D);
/* Text/Regular/Light */
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
text-align: center;
  }
  
  @media screen and (max-width: 576px) {
    .footerCopyright p {
      font-family: "Open Sans";
      font-style: normal;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      text-align: center;

      padding-top: 1rem;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .footerCopyright p {
      font-family: "Open Sans";
      font-style: normal;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      text-align: center;
  
      padding-top: 1rem;
    }
  }