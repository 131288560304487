.BlogGrandParent {
  padding-top: 8rem;
}
@media screen and (max-width: 576px) {
  .BlogGrandParent {
    padding-top: 5rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.blogMainParent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
  .blogMainParent {
    flex-direction: column;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .blogMainParent {
    flex-direction: column;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.blogMainChild {
  width: 45%;
}
@media screen and (max-width: 576px) {
  .blogMainChild {
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.blogMainChild1 {
  /* width: 45%; */
}
.blogMainChild1 img {
  border-radius: 14px;
  height: 70vh !important;
}
@media screen and (max-width: 576px) {
  .blogMainChild1 img {
    height: 40vh !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .blogMainChild1 img {
    height: 40vh !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .blogMainChild1 img {
    height: 40vh !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .blogMainChild1 img {
    height: 40vh !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .blogMainChild1 img {
    height: 60vh !important;
  }
}

@media screen and (max-width: 576px) {
  .blogMainParent {
    text-align: center;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .blogMainParent {
    text-align: center;
    padding-top: 0rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}

.blogMainParent h6 {
  color: var(--blue-blue-500, #1c233d);

  /* Heading/H5/Regular */
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.blogMainParent h2 {
  color: var(--blue-blue-500, #1c233d);

  /* Heading/H1/Extra Bold */
  font-family: "Playfair Display";
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 67.2px */
}

@media screen and (max-width: 576px) {
  .blogMainParent h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .blogMainParent h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .blogMainParent h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px !important;
    line-height: 120%;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .blogMainParent h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px !important;
    line-height: 120%;
  }
}

.blogMainParent p {
  color: var(--neutral-neutral-500, #2b2b2b);

  /* Heading/H5/Light */
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
  padding-top: 0.5rem;
}
@media screen and (max-width: 576px) {
  .blogMainParent p {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    margin-left: 0% !important;
    width: 100% !important;
    text-align: center !important;
    padding-top: 1rem !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .blogMainParent p {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    margin-left: 0% !important;
    width: 100% !important;
    text-align: center !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .blogMainParent p {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    margin-left: 0% !important;
    width: 100% !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .blogMainParent p {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    margin-left: 0% !important;
    width: 100% !important;
  }
}
