.Practice4GrandMain {
  background: var(--blue-blue-50, #f1f3f9);
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-top: 5rem;
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 577px) and (max-width: 768px) {
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice4Main {
}

.Practice4Parent h6 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.Practice4Child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}
@media screen and (max-width: 576px) {
  .Practice4Child {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice4Child {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Practice4Child{
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Practice4Child{
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice4SubChild {
}

.Practice4SubChild h4 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 41.6px */
  letter-spacing: -0.64px;
}
@media screen and (max-width: 576px) {
  .Practice4SubChild h4 {
    font-size: 24px;
    padding-top: 2rem;
  }  
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice4SubChild h4 {
    font-size: 24px;
    padding-top: 2rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Practice4SubChild h4 {
    font-size: 24px;
    padding-top: 2rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Practice4SubChild h4 {
    font-size: 26px;
    padding-top: 2rem;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice4SubChild h5 {
  color: var(--neutral-neutral-500, #2b2b2b);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
  padding-top: 1rem;
}
@media screen and (max-width: 576px) {
  .Practice4SubChild h5 {
    font-size: 16px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice4SubChild h5 {
    font-size: 16px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Practice4SubChild h5 {
    font-size: 18px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice4SubChild button {
  border-radius: 2px;
  border: 1px solid var(--blue-blue-500, #1c233d);
  background: #fff;
  padding: 0.3rem 1rem;
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-top: 2rem;
}
@media screen and (max-width: 576px) {
  .Practice4SubChild button {
    border: 1px solid var(--blue-blue-500, #1c233d);
    font-size: 14px;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice4SubChild button {
    border: 1px solid var(--blue-blue-500, #1c233d);
    font-size: 14px;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice4Div {
  border: 1px solid #eb950a;
  margin-top: 1rem;
}
