.Practice3main {
  margin-top: 8rem;
}
@media screen and (max-width: 576px) {
  .Practice3main {
    margin-top: 4rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice3main {
    margin-top: 4rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice3Parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .Practice3Parent {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice3Parent {
    display: flex;
    /* flex-direction: column;
    text-align: center; */
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice3Parent1 {
  width: 40%;
}
@media screen and (max-width: 576px) {
  .Practice3Parent1 {
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice3Parent1 {
    width: 45%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.Practice3Parent1 img {
  border-radius: 12px;
}
@media screen and (max-width: 576px) {
  .Practice3Parent1 img {
    border-radius: 12px !important;
    height: 30vh !important;
    /* width: 100% !important; */
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice3Parent1 img {
    border-radius: 12px !important;
    height: 30vh;
    /* width: 100% !important; */
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Practice3Parent1 img {
    border-radius: 12px !important;
    height: 40vh;
    /* width: 100% !important; */
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Practice3Parent1 img {
    border-radius: 12px !important;
    height: 50vh;
    /* width: 100% !important; */
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .Practice3Parent1 img {
    border-radius: 12px !important;
    height: 50vh;
    /* width: 100% !important; */
  }
}
.Practice3Parent2 {
  width: 40%;
}
@media screen and (max-width: 576px) {
  .Practice3Parent2 {
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice3Parent2 {
    width: 45%;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.Practice3Parent2 h4 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 41.6px */
  letter-spacing: -0.64px;
}
@media screen and (max-width: 576px) {
  .Practice3Parent2 h4 {
    font-size: 18px;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice3Parent2 h4 {
    font-size: 18px;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Practice3Parent2 h4 {
    font-size: 24px;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Practice3Parent2 h4 {
    font-size: 24px;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.Practice3Parent2 p {
  /* Heading/H5/Regular */
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}
@media screen and (max-width: 576px) {
  .Practice3Parent2 p {
    font-size: 14px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice3Parent2 p {
    font-size: 14px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Practice3Parent2 p {
    font-size: 16px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Practice3Parent2 p {
    font-size: 16px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.Practice3Parent2 button {
  border-radius: 2px;
  border: 1px solid var(--blue-blue-500, #1c233d);
  background: #fff;
  padding: 0.3rem 1rem;
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 1rem;
}
@media screen and (max-width: 576px) {
  .Practice3Parent2 button {
    border-radius: 2px;
    border: 1px solid var(--blue-blue-500, #1c233d);
    background: #fff;
    padding: 0.3rem 0.5rem;
    color: var(--blue-blue-500, #1c233d);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 0rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice3Parent2 button {
    border-radius: 2px;
    border: 1px solid var(--blue-blue-500, #1c233d);
    background: #fff;
    padding: 0.3rem 0.5rem;
    color: var(--blue-blue-500, #1c233d);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 0rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}


.Practice3Category {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px;
  margin-top: 3rem;
}

@media screen and (max-width: 576px) {
  .Practice3Category {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 5px;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice3Category {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 5px;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Practice3Category {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 5px;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.Practice3Category h4 {
  border-bottom: 1px solid #eb950a;
  margin-top: 1rem;
  width: 80%;
  padding: 1rem;
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 41.6px */
  letter-spacing: -0.64px;
}
@media screen and (max-width: 576px) {
  .Practice3Category h4 {
    border-bottom: 1px solid #eb950a;
    margin-top: 1rem;
    width: 80%;
    padding: 0.1rem;
    font-size: 16px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice3Category h4 {
    border-bottom: 1px solid #eb950a;
    margin-top: 1rem;
    width: 80%;
    padding: 0.1rem;
    font-size: 16px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Practice3Category h4 {
    border-bottom: 1px solid #eb950a;
    margin-top: 1rem;
    width: 80%;
    padding: 0.1rem;
    font-size: 16px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Practice3Category h4 {
    border-bottom: 1px solid #eb950a;
    margin-top: 1rem;
    width: 80%;
    padding: 0.1rem;
    font-size: 16px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
