.teamMain {
    margin-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  
  .modalHeadermain{ 
  background: var(--blue-blue-500, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #1C233D);
  }
  .modalHeadermain button{ 
  background-color: #fff;
  
  }
  
  .teamMain h6 {
    color: var(--blue-blue-500, #1C233D);
    text-align: center;
    font-family: "Open Sans";
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 57.6px */
    letter-spacing: -0.96px;
  }
  @media screen and (max-width: 576px) {
    .teamMain h6 {
      font-size: 24px;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamMain h6 {
      font-size: 24px;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamMain h6 {
      font-size: 24px;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .teamMain h6 {
      font-size: 28px;
    }
  }
  @media screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  
  
  
  .teamFlex {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-top: 3rem !important;
    justify-content: space-evenly;
    align-items: center !important;
    text-align: center;
  }
  
  @media screen and (max-width: 576px) {
    .teamFlex {
      display: flex !important;
      flex-wrap: wrap !important;
      margin-top: 2rem !important;
      justify-content: space-between !important;
  
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamFlex {
      display: flex !important;
      flex-wrap: wrap !important;
      margin-top: 2rem !important;
      justify-content: space-between !important;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamFlex {
      display: flex !important;
      flex-wrap: wrap !important;
      margin-top: 2rem !important;
      justify-content: space-between !important;
    }
  }
  
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .teamFlex {
      display: flex !important;
      flex-wrap: wrap !important;
      margin-top: 2rem !important;
      justify-content: space-between !important;
    }
  }
  
  @media screen and (min-width: 1201px) and (max-width: 1400px) {
    .teamFlex {
      display: flex !important;
      flex-wrap: wrap !important;
      margin-top: 2rem !important;
      justify-content: space-between !important;
    }
  }
  
  @media screen and (max-width: 576px) {
    .teamFlex {
      /* flex-direction: column !important; */
      text-align: center !important;
      justify-content: space-evenly !important;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamFlex {
      text-align: center !important;
      justify-content: space-evenly !important;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamFlex {
      text-align: center !important;
      justify-content: space-evenly !important;
    }
  }
  
  /* .custom-dialog-width {
    max-width: 1000px;
  } */
  
  .teamChild {
    padding-top: 1rem !important;
    /* flex-basis: calc(33.33% - 10px); */
  }
  .teamChildImagetry img {
    border-radius: 8px !important;
  }
  
  @media screen and (max-width: 576px) {
    .teamChildImagetry img {
      border-radius: 8px !important;

    }
  }

  .teamChild1 {
    padding-top: 1rem !important;
    display: flex !important;
    flex-direction: column;
    align-items: center ;
  }
  
  .teamChildImagetry:hover img {
    /* box-shadow: 0 0 10px 10px #ccc; */
  }
  
  @media screen and (max-width: 576px) {
    .teamChild1 {
      display: flex !important;
      flex-direction: column !important;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamChild1 {
      display: flex !important;
      flex-direction: column !important;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamChild1 {
      display: flex !important;
      flex-direction: column !important;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .teamChild1 {
      display: flex !important;
      flex-direction: column !important;
    }
  }
  
  
  .teamChild1 img {
  
    /* height: 350px;
    width: 350px;
    border-radius: 8px; */
    
  }
  
  @media screen and (max-width: 576px) {
    .teamChild img {
      height: 200px !important;
      width: 160px !important;
      border-radius:18px !important;
    }
  }
  @media screen and (max-width: 576px) {
    .teamChild1 img {
      height: 180px !important;
      width: 100% !important;
      border-radius: 4.1958px !important;
      margin-right: 0rem !important;
      margin-bottom: 0rem !important;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamChild1 img {
      margin-right: 0rem !important;
      height: 250px;
      width: 100%;
      margin-bottom: 0rem !important;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamChild1 img {
      margin-right: 0rem !important;
      height: 250px;
      width: 100%;
      margin-bottom: 0rem !important;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .teamChild1 img {
      margin-right: 0rem !important;
      height: 250px;
      width: 100%;
      margin-bottom: 0rem !important;
    }
  }
  
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamChild img {
      height: 200px !important;
      width: 200px !important;
      border-radius: 4.1958px !important;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamChild img {
      /* height: 270px !important;
            width: 270px !important; */
      border-radius: 4.1958px !important;
    }
  }
  
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .teamChild img {
      /* height: 250px !important;
            width: 250px !important; */
      border-radius: 4.1958px !important;
    }
  }
  
  .modalBodyMain{
      background: rgba(10, 11, 13, 0.90);
      background: var(--blue-blue-500, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #1C233D);
  }
  
  .teamChildName {
    margin-top: 0.5rem !important;
    text-align: center;
  }
  
  .teamChildName1 {
    margin-top: 5% !important;
  }
  
  @media screen and (max-width: 576px) {
    .teamChildName1 {
      margin-top: 5% !important;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamChildName1 {
      margin-top: 5% !important;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamChildName1 {
      margin-top: 5% !important;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .teamChildName1 {
      margin-top: 5% !important;
    }
  }
  
  .teamChildProfile p {
    color: var(--colours-text-light, #fff);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    text-align: justify;
  }
  @media screen and (max-width: 576px) {
    .teamChildProfile p {
      text-align: justify;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamChildProfile p {
      text-align: justify;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamChildProfile p {
      text-align: justify;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .teamChildProfile p {
      text-align: justify;
    }
  }
  
  
  
  .teamChildName h6 {
    color: var(--colours-text-light, #fff);
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    
    display: inline-block;
    border-bottom: 1px solid var(--foundation-orange-orange-500, #FE6603);;
  }
  .teamChildName1 h6 {
    color: var(--blue-blue-500, #FE6603);
    text-align: center;
  
    font-family: "Open sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.84px;
  }
  
  @media screen and (max-width: 576px) {
    .teamChildName1 h6 {
      font-size: 24px !important;
      text-align: center !important;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamChildName1 h6 {
      font-size: 24px !important;
      text-align: center !important;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamChildName1 h6 {
      font-size: 24px !important;
      text-align: center !important;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .teamChildName1 h6 {
      font-size: 24px !important;
      text-align: center !important;
    }
  }
  @media screen and (min-width: 1201px) and (max-width: 1400px) {
    .teamChildName1 h6 {
      font-size: 24px !important;
      text-align: center !important;
    }
  }
  
  
  @media screen and (max-width: 576px) {
    .teamChildName h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: #060606;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamChildName h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: #060606;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamChildName h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: #060606;
    }
  }
  
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .teamChildName h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: #060606;
    }
  }
  
  .teamChildName p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 0%;
    color: #797979;
    padding-top: 0.5rem;
  }
  .teamChildName1 p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 0%;
    color: #797979;
    padding-top: 0.5rem !important;
  }
  
  .teamChildName h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #02040f;
  }
  .teamChildName1 h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #02040f;
    padding-top: 1rem !important;
    white-space: pre-line;
    margin-bottom: 4rem !important;
  }
  
  @media screen and (max-width: 576px) {
    .teamChildName p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 0%;
      color: #797979;
    }
  }
  
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamChildName p {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 0%;
      color: #797979;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamChildName p {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 0%;
      color: #797979;
    }
  }
  
  
  .TeamHeaderFlex {
    display: flex !important;
    justify-content: center;
  }
  .TeamHeaderFlex2 {
    display: flex !important;
    justify-content: center !important;
    margin-top: 2rem !important;
  }
  
  .teambutton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    margin-left: 40%;
    margin-top: 2rem;
  }
  .teambutton button {
    background: none;
    border: 0px;
    color: var(--foundation-orange-orange-500, #fff) !important;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }
  
  
  .teamscrolmodal .teammodalcontent {
    width: 100%;
    height: 100vh;
  }
  @media screen and (max-width: 576px) {
    .teamscrolmodal .teammodalcontent {
      width: 100%;
      height: 100vh;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .teamscrolmodal .teammodalcontent {
      width: 100%;
      height: 100vh;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .teamscrolmodal .teammodalcontent {
      width: 100%;
      height: 100vh;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .teamscrolmodal .teammodalcontent {
      width: 100%;
      height: 100vh;
    }
  }