.Practice1Main {
  padding-top: 8rem;
  /* background: var(--blue-blue-50, #f1f3f9); */
  padding-bottom: 8rem;
  /* background: var(--blue-blue-500, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #1C233D); */
}
 
.Practice1Parent {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 576px) {
  .Practice1Parent {
    flex-direction: column !important;
    text-align: center;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice1Parent {
    /* flex-direction: column !important;
    text-align: center; */
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice1Child {
  width: 60%;
}
@media screen and (max-width: 576px) {
  .Practice1Child {
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice1Child {
    width: 100%;
    margin-right: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice1Child h6 {
  color: var(--blue-blue-500, #1c233d);
  /* color: #fff; */
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}
@media screen and (max-width: 576px) {
  .Practice1Child h6 {
    font-size: 24px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice1Child h6 {
    font-size: 24px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice1Child h2 {
  color: var(--blue-blue-500, #1c233d);
  /* color: #fff; */
  font-family: "Playfair Display";
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 67.2px */
}

@media screen and (max-width: 576px) {
  .Practice1Child h2 {
    font-size: 24px;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice1Child h2 {
    font-size: 32px;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .Practice1Child h2 {
    font-size: 32px;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Practice1Child h2 {
    font-size: 36px;
    padding-top: 1rem;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice1Image {
  /* width: 40%; */
}
@media screen and (max-width: 576px) {
  .Practice1Image {
    width:100%;
    padding-top: 2rem;
    text-align: center;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Practice1Image {
    width: 100%;
    /* padding-top: 1rem; */
    /* text-align: center; */
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.Practice1Image img {
  border-radius: 12px !important;
  height: 70vh !important;
}
@media screen and (max-width: 576px) {
    .Practice1Image img {
       height: 50vh !important;
      }
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .Practice1Image img {
      height: 40vh !important;
     }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .Practice1Image img {
      height: 50vh !important;
     }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .Practice1Image img {
      height: 70vh !important;
     }
  }
  @media screen and (min-width: 1201px) and (max-width: 1400px) {
    .Practice1Image img {
      height: 70vh !important;
     }
  }