.About3Main {
  padding: 3rem 2rem;
  background-image: url("/public/Journey.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vh;
}
@media screen and (max-width: 576px) {
  .About3Main {
    padding-bottom: 30em !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About3Main {
    padding-bottom: 25rem !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About3Main {
    padding-bottom: 25rem !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .About3Main {
    padding-bottom: 25rem !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .About3Main {
    padding-bottom: 25rem !important;
  }
}

.About3Container {
  display: flex;
  padding-top: 5rem;
}
@media screen and (max-width: 576px) {
  .About3Container {
    flex-direction: column;
    padding-top: 3rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About3Container {
    flex-direction: column;
    padding-top: 3rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About3Container {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .About3Container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .About3Container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 2rem;
  }
}

.About3Parent h2 {
  color: var(--neutral-white, #fff);
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 57.6px */
  letter-spacing: -0.96px;
}
@media screen and (max-width: 576px) {
  .About3Parent h2 {
    font-size: 28px;
    font-weight: 500;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About3Parent h2 {
    font-size: 28px;
    font-weight: 500;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About3Parent h2 {
    font-size: 28px;
    font-weight: 500;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .About3Parent h2 {
    font-size: 24px;
    font-weight: 500;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .About3Parent h2 {
    font-size: 28px;
    font-weight: 500;
  }
}
.About3Parent span {
  color: var(--neutral-white, #fff);
  font-family: "Playfair Display";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
@media screen and (max-width: 576px) {
  .About3Parent span {
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About3Parent span {
    font-size: 24px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .About3Parent span {
    font-size: 24px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.About3Parent p {
  color: var(--neutral-white, #fff);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

@media screen and (max-width: 576px) {
  .About3Parent p {
    font-size: 14px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .About3Parent p {
    font-size: 14px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .About3Parent p {
    font-size: 14px;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .About3Parent p {
    font-size: 16px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .About3Parent p {
    font-size: 16px;
  }
}
