.Practice2Main {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
  .Practice2Main {
    flex-direction: column;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .Practice2Main {
        flex-direction: column;
      }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.Practice2Main h2 {
  color: var(--blue-blue-500, #1c233d);
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 57.6px */
  letter-spacing: -0.96px;
  width: 40%;
}
@media screen and (max-width: 576px) {
  .Practice2Main h2 {
    font-size: 24px;
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .Practice2Main h2 {
        font-size: 24px;
        width: 100%;
      }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
    .Practice2Main h2 {
        font-size: 28px;
      }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .Practice2Main h2 {
        font-size: 32px;
      }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
.Practice2Main p {
  color: var(--neutral-neutral-500, #2b2b2b);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
  width: 40%;
}
@media screen and (max-width: 576px) {
    .Practice2Main p {
        font-size: 18px;
        width: 100%;
        padding-top: 0.5rem;
      }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .Practice2Main p {
        font-size: 18px;
        width: 100%;
        padding-top: 0.5rem;
      }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
    .Practice2Main p {
        font-size: 18px;
      }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
    .Practice2Main p {
        font-size: 20px;
      }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}

.Practice2Child {
  border: 1px solid #eb950a;
}

@media screen and (max-width: 576px) {
    .Practice2Child {
        width: 80%;
        margin-top: 0.5rem;
      }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .Practice2Child {
        width: 80%;
        margin-top: 0.5rem;
      }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}



